<template>
  <div class="activities">
    <MayorNewsFilter />
    <div>
      <ul v-if="mayorNews.data && mayorNews.data.length" class="mayor-activities__list _items_list">
        <li class="_item" :key="index" v-for="(item, index) in mayorNews.data">
          <div class="img">
            <router-link
              :to="{
                name: 'news_item',
                params: { id: item.id, url: item.link },
              }"
              class="img__container"
            >
              <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
            </router-link>
          </div>
          <div class="news__mayor__header _item__header">
            <a
              href="#"
              class="_item__category news__mayor__color"
              v-if="item.category && item.category.title"
            >
              {{ item.category.title }}
            </a>
            <div class="_item__time news__mayor__color" v-if="item.date_publication">
              <time :datetime="item.date_publication | robotDate">{{ item.date_publication | humanDateRu }}</time>
            </div>
          </div>
          <router-link
            :to="{
              name: 'news_item',
              params: { id: item.id, url: item.link },
            }"
            class="_item__text"
          >
            {{ item.title }}
          </router-link>
        </li>
      </ul>

      <p v-else-if="!$store.state.filterResponse" class="_item__text">Новостей нет</p>
      <p v-else-if="$store.state.filterData.get('title')" class="_item__text">
        По запросу "{{ $store.state.filterData.get("title") }}" ничего не найдено
      </p>
      <p v-else class="_item__text">По вашему запросу ничего не найдено</p>
    </div>

    <a v-if="loading" href="#" class="show-more-link">
      <LoadingIndicator title="Загрузка" />
    </a>
    <a
      @click.prevent="submitFilterForm"
      v-else-if="$store.state.filterResponse && $store.state.filterResponse.next_page_url"
      href="#"
      class="show-more-link"
    >
      Показать ещё
    </a>
    <a @click.prevent="loadMore" v-else-if="mayorNews.next_page_url" href="#" class="show-more-link">
      Показать ещё
    </a>
  </div>
</template>

<script>
import axios from "axios";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import MayorNewsFilter from "@/views/mayor/mayor_activity/components/MayorNewsFilter.vue";

export default {
  name: "MayorActivitiesList",
  components: {
    LoadingIndicator,
    MayorNewsFilter,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    mayorNews() {
      if (this.$store.state.filterResponse) {
        return this.$store.state.filterResponse;
      }
      return this.$store.state.mayor_news_page?.mayor_news;
    },
  },
  methods: {
    /**
     * Фильтр новостей
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      if (!this.loading) {
        this.loading = true;
        await this.$store
          .dispatch("POST_NEWS_FILTER", {
            data: this.$store.state.filterData,
            url: this.$store.state.filterResponse.next_page_url,
          })
          .then((response) => {
            this.loading = false;
            const oldResponse = this.$store.state.filterResponse;
            this.$store.state.filterResponse = response.data.news;
            this.$store.state.filterResponse.data = oldResponse.data.concat(
              this.$store.state.filterResponse.data
            );
          })
          .catch((response) => {
            this.loading = false;
            console.error(response.data);
          });
      }
    },
    /**
     * Загрузить еще новости
     * @returns {Promise<void>}
     */
    async loadMore() {
      if (!this.loading) {
        this.loading = true;
        await axios
          .get(this.mayorNews.next_page_url)
          .then(({ data }) => {
            this.loading = false;
            // добавляем к существующим новые документы с новой страницы пагинации
            this.$store.state.mayor_news_page.mayor_news.data =
              this.$store.state.mayor_news_page.mayor_news.data.concat(data.mayor_news.data);
            this.$store.state.mayor_news_page.mayor_news.next_page_url = data.mayor_news.next_page_url;
          })
          .catch(({ response }) => {
            this.loading = false;
            console.error(response?.data?.message);
          });
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.$store.state.filterData = null;
      this.$store.state.filterResponse = null;
      this.$store.state.filterList = [];
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
    },
  },
};
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'

.mayor {
  &-activities__list {
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 32px
    +below(1200px) {
      column-gap 24px
    }
    +below(1100px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(768px) {
      grid-template-columns repeat(2, 1fr)
      gap 24px
    }
    +below(480px) {
      grid-template-columns 1fr
      gap 16px
    }
  }
}
</style>
