<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <section class="section">
          <MayorActivitySlider />
          <MayorActivitiesList />
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import MayorActivitySlider from "@/views/mayor/mayor_activity/components/MayorActivitySlider.vue";
import MayorActivitiesList from "@/views/mayor/mayor_activity/components/MayorActivitiesList.vue";

export default {
  name: "MayorActivityPage",
  async asyncData({ store }) {
    await store.dispatch("GET_MAYOR_NEWS");
  },
  components: {
    MayorActivitySlider,
    MayorActivitiesList,
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/mayor/index.styl"
@import "~@/styles/parts/swiper.styl"
@import "~@/styles/parts/mycity/poster/posters.styl"
</style>
