<template>
  <div>
    <h2>Деятельность главы города</h2>
    <div class="posters">
      <div v-if="sliderNews && sliderNews.length" class="slider">
        <swiper ref="bigSwiper" :options="bigSwiperOptions">
          <swiper-slide v-for="(item, index) in sliderNews" :key="index">
            <div class="slide">
              <div class="slider__img">
                <router-link
                  :to="{
                    name: 'news_item',
                    params: { id: item.id, url: item.link },
                  }"
                  class="img__container"
                >
                  <div class="img__upper__layer"></div>
                  <img
                    :src="item.head_img | image($store.state.api)"
                    :alt="item.head_img | image_alt"
                    class="swiper-lazy"
                  />
                  <!--                    <div class="swiper-lazy-preloader"></div>-->
                </router-link>
              </div>
              <div class="slider__info__wrap">
                <div class="slider__info">
                  <router-link
                    v-if="item.title"
                    :to="{
                      name: 'news_item',
                      params: { id: item.id, url: item.link },
                    }"
                    class="slider__title text__cut"
                  >
                    {{ item.title }}
                  </router-link>
                  <div class="slider__category__wrap">
                    <div class="slider__category" v-if="item.category && item.category.title">
                      {{ item.category.title }}
                    </div>
                    <div class="slider__time">
                      <time :datetime="item.date_publication | robotDate">{{
                        item.date_publication | humanDateSliderRu
                      }}</time>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div>
          <a @click.prevent="bigSwiper.slidePrev()" class="slider__controls__left">
            <ArrowLeft />
          </a>
          <div class="slider__controls__list" slot="pagination"></div>
          <a @click.prevent="bigSwiper.slideNext()" class="slider__controls__right">
            <ArrowRight />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import ArrowLeft from "@/components/svg/ArrowLeft.vue";
import ArrowRight from "@/components/svg/ArrowRight.vue";

export default {
  name: "MayorActivitySlider",
  components: {
    Swiper,
    SwiperSlide,
    ArrowLeft,
    ArrowRight,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      loading: false,
      bigSwiperOptions: {
        slidesPerView: 1,
        loop: false,
        clickable: true,
        pagination: {
          el: ".slider__controls__list",
          clickable: true,
        },
        speed: 800,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        preloadImages: false,
        lazy: {
          loadOnTransitionStart: true,
        },
      },
    };
  },
  computed: {
    sliderNews() {
      return this.$store.state.mayor_news_page?.slider_news.data;
    },
    bigSwiper() {
      return this.$refs.bigSwiper.$swiper;
    },
  },
  mounted() {
    if (this.slides && this.slides.length >= 2) {
      this.bigSwiper.slideTo(0, 1000, false);
    }
  },
};
</script>
